@font-face {
  font-family: 'Roboto';
  src:url('../fonts/roboto/Roboto-Regular-webfont.eot');
  src: url('../fonts/roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/Roboto-Regular-webfont.woff') format('woff'),
  url('../fonts/roboto/Roboto-Regular-webfont.ttf') format('truetype'),
  url('../fonts/roboto/Roboto-Regular-webfont.svg#RobotoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-bold';
  src: url('../fonts/roboto/Roboto-Bold-webfont.eot');
  src: url('../fonts/roboto/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/Roboto-Bold-webfont.woff') format('woff'),
  url('../fonts/roboto/Roboto-Bold-webfont.ttf') format('truetype'),
  url('../fonts/roboto/Roboto-Bold-webfont.svg#RobotoBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon/fonts/icomoon.eot?6jtuk');
  src:url('../fonts/icomoon/fonts/icomoon.eot?6jtukb#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/fonts/icomoon.ttf?6jtukb') format('woff'),
  url('../fonts/icomoon/fonts/icomoon.ttf?6jtukb') format('truetype'),
  url('../fonts/icomoon/fonts/icomoon.svg?6jtukb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

/***********Objects*****************/
.icon-archaeology-context:before {
  content: "\e953";
}

.icon-archaeology-finds:before {
  content: "\e92a";
}

.icon-archaeology-samples:before {
  content: "\e95a";
}

.icon-artwork:before { //artwork
  content: "\e94f";
}

.icon-building:before {
  content: "\e952";
}

.icon-design:before {
  content: "\e954";
}

.icon-plant:before {
  content: "\e98d";
}

.icon-image:before { //photograph
  content: "\e955";
}

.icon-object:before { //Thing
  content: "\e956";
}

.icon-office:before { //architecture
  content: "\e957";
}

.icon-photo-ex:before { //photograph_copy
  content: "\e958";
}

.icon-record:before { //record object
  content: "\e98b";
}

/************Events****************/
.icon-accession:before {
  content: "\e96e";
}
.icon-accession-small:before {
  content: "\e972";
}
.icon-accession2:before {
  content: "\e970";
}
.icon-accession3:before {
  content: "\e971";
}
.icon-acquisition:before {
  content: "\e922";
}
.icon-archaeology-investigations:before {
  content: "\e961";
}
.icon-adm-event:before {
  content: "\e989";
}
.icon-analyze:before {
  content: "\e639";
}
.icon-annotate:before {
  content: "\e95b";
}
.icon-care:before {
  content: "\e900";
}
.icon-comment:before {
  content: "\e608";
}
.icon-completed-task:before {
  content: "\e95c";
}
.icon-condition-assessment:before { //conditionAssessment
  content: "\e95d";
}
.icon-flowering-time:before {
  content: "\e90a";
}
.icon-damage:before {
  content: "\e94a";
}
.icon-damage2:before {
  content: "\e95e";
}
.icon-deviation:before { //deviation, observationEvent, observationEventBuilding
  content: "\e95f";
}
.icon-entry-procedure:before {
  content: "\e963";
}
.icon-exit-procedure:before {
  content: "\e960";
}
.icon-exhibition:before {
  content: "\e93e";
}
.icon-heartQuestion:before { //?
  content: "\e65a";
}
.icon-insurance:before {
  content: "\e92a";
}
.icon-loan:before {
  content: "\e962";
}
.icon-package:before {
  content: "\e974";
}
.icon-procedure:before {
  content: "\e909";
}
.icon-procedure2:before {
  content: "\e959";
}
.icon-publishing:before {
  content: "\e94d";
}
.icon-purchase:before {
  content: "\e93c";
}
.icon-ripening-time:before {
  content: "\e90b";
}
.icon-salvage_priority:before {
  content: "\e937";
}
.icon-step-in-to:before {
  content: "\e948";
}
.icon-task:before { //task
  content: "\e964";
}
.icon-treatment:before { //treatment
  content: "\e60e";
}
.icon-user:before { //actor and user
  content: "\e62f";
}
.icon-use-measurement:before {
  content: "\e99a";
}

.icon-history:before { //history
  content: "\e986";
}

/******** Events: Using Material Icons ***********/
.icon-audit:before { //
  font-family: "Material Icons", serif;
  content: "\ea9b";
}
.icon-use-restriction:before { //
  font-family: "Material Icons", serif;
  content: "\e769";
}
.icon-environmental-condition:before { //
  font-family: "Material Icons", serif;
  content: "\e761";
}

.icon-review-result:before { // Library Books
  font-family: "Material Icons", serif;
  content: "\e02f";
}

.icon-object-valuation:before { // Monetization On
  font-family: "Material Icons", serif;
  content: "\e263";
}

.icon-user-contribution:before { // Record Voice Over
  font-family: "Material Icons", serif;
  content: "\e91f";
}

/***********attachment, file, media **************/
.icon-audio:before {
  content: "\e98c";
}

.icon-audio2:before {
  content: "\ea27";
}

.icon-attachment:before { //attachment Replace icon-file??
  content: "\e603";
}

.icon-attachment2:before { //attachment Replace icon-file??
  content: "\e623";
}

.icon-file:before { //attachment
  content: "\e650";
}

.icon-file-excel:before {
  content: "\e653";
}

.icon-file-list:before {
  content: "\e965";
}

.icon-file-pdf:before {
  content: "\e651";
}

.icon-file-powerpoint:before {
  content: "\e654";
}

.icon-file-word:before {
  content: "\e652";
}

.icon-images:before { //image
  content: "\e613";
}

/*********************************/

.icon-active-relation:before {
  //content: "\e925";
  content: "\e968";
}

.icon-archive-close:before {
  content: "\e90c";
}

.icon-archive-open:before {
  content: "\e90d";
}

.icon-arrow-down2:before {
  content: "\e982";
}

.icon-arrow-up2:before {
  content: "\e983";
}

.icon-bell:before {
  content: "\e96f";
}

.icon-box:before {
  content: "\e985";
}

.icon-calendar:before {
  content: "\e622";
}

.icon-camera:before {
  content: "\e607";
}

.icon-child-of:before { //child-of and mother-to are opposite from selection.json
  //content: "\e910";
  content: "\e967";
}

.icon-circle:before {
  content: "\e907";
}

.icon-close:before { //used in procedure steps
  content: "\e62b";
}

.icon-concept:before {
  content: "\e915";
}

.icon-concept-child:before {
  content: "\e916";
}

.icon-concept-grandchild:before {
  content: "\e914";
}

.icon-copy:before {
  content: "\e92c";
}

.icon-copyright:before {
  content: "\e658";
}

.icon-create-new:before {
  content: "\e97e";
}

.icon-download:before {
  content: "\e629";
}

.icon-edit-alternative:before {
  content: "\e60b";
}

.icon-expand:before {
  content: "\e60c";
}

.icon-filter:before { //In use?
  content: "\ea5b";
}

.icon-filter2:before {
  content: "\e932";
}

.icon-folder-open:before {
  content: "\e60d";
}

.icon-hamburger:before {
  content: "\e931";
}

.icon-help:before{
  content: "\e950";
}

.icon-home:before{ //in use
  content: "\e940";
}

.icon-hour-glass:before{
  content: "\e976";
}

.icon-information:before{
  content: "\e94e";
}

.icon-image-attention:before {
  content: "\e975";
}

.icon-link:before {
  content: "\e90e";
}

.icon-light-bulb:before {
  content: "\e94c";
}

.icon-list-view:before {
  content: "\e933";
}

.icon-place:before {
  content: "\e616";
}

.icon-location:before {
  content: "\e935";
}

.icon-placement:before {
  content: "\e920";
}

.icon-placement2:before {
  content: "\e91f";
}

.icon-playlist-edit:before {
  content: "\e988";
}

.icon-playlist-remove:before {
  content: "\e98a";
}

.icon-minus:before { //in use?
  content: "\e980";
}

.icon-minus-full-circle:before { //in use?
  content: "\e901";
}

.icon-more:before {
  content: "\e614";
}

.icon-mother-to:before { //child-of and mother-to are opposite from selection.json
  //content: "\e904";
  content: "\e966";
}

.icon-overview:before {
  content: "\e911";
}

.icon-p:before {
  content: "\e943";
}

.icon-passive-relation:before {
  //content: "\e926";
  content: "\e968";
}
.icon-paste:before {
  content: "\e60b";
}

.icon-pin:before {
  content: "\e61e";
}

.icon-play:before {
  content: "\e631";
}

.icon-play2:before {
  content: "\e977";
}

.icon-plus-circle:before {
  content: "\e902";
}

.icon-plus:before {
  content: "\e615";
}

.icon-plus-big:before {
  content: "\e942";
}

.icon-primus:before {
  content: "\e987";
}

.icon-print:before { //in use
  content: "\e638";
}

.icon-report:before {
  content: "\e63b";
}

.icon-row-sort:before {
  content: "\e903";
}

.icon-search:before {
  content: "\e62c";
}

.icon-select:before { //used in procedure steps
  content: "\e620";
}
.icon-select1:before {
  content: "\e97f";
}

.icon-settings:before { //used in menu on admin-page
  content: "\e944";
}

.icon-spinner:before {
  content: "\e984";
}

.icon-sort:before {
  content: "\e96a";
}

.icon-sort-asc:before {
  content: "\e913";
}

.icon-sort-desc:before {
  content: "\e912";
}

.icon-square:before {
  content: "\e908";
}

.icon-star:before { //RecommendationEvent
  content: "\e96d";
}

.icon-tag:before { //ct_48
  content: "\e906";
}

.icon-template:before {
  content: "\e969"
}

.icon-trash:before {
  content: "\e62e";
}

.icon-unlink:before {
  content: "\e90f";
}

.icon-upload:before {
  content: "\e62a";
}

.icon-users:before { //used in menu on admin-page
  content: "\e630";
}

.icon-video:before {
  content: "\e631";
}

.icon-warning:before {
  content: "\e96b";
}


/********hazard-icons**********/
.hazards-main-icon{
  color: black;
  margin-left: -1em;
}
.icon-chemical-hazard:before,
.icon-explosion-hazard:before,
.icon-toxic-hazard:before,
.icon-health-hazard:before,
.icon-gas-hazard:before,
.icon-flammable-hazard:before,
.icon-environment-hazard:before,
.icon-chronic-hazard:before,
.icon-oxidizing-hazard:before,
.icon-corrosive-hazard:before {
  content: "chemicalBack";
  color: white;
  margin-right: -1em;
}

.icon-chemical-hazard,
.icon-explosion-hazard,
.icon-toxic-hazard,
.icon-oxidizing-hazard,
.icon-health-hazard,
.icon-gas-hazard,
.icon-flammable-hazard,
.icon-chronic-hazard,
.icon-environment-hazard,
.icon-corrosive-hazard {
  color:red;
  font-size: 32px;
}
.icon-chemical-hazard:after{
  content:' ';
  padding-left: 1em;
  @extend .hazards-main-icon;
}
.icon-explosion-hazard:after {
  content: "chemicalExplosion";
  @extend .hazards-main-icon;
}
.icon-corrosive-hazard:after {
  content:"chemicalCorrosive";
  @extend .hazards-main-icon;
}
.icon-toxic-hazard:after {
  content:"chemicalToxic";
  @extend .hazards-main-icon;
}
.icon-oxidizing-hazard:after {
  content:"chemicalOxidising";
  @extend .hazards-main-icon;
}
.icon-health-hazard:after {
  content:"chemicalHealth";
  @extend .hazards-main-icon;
}
.icon-gas-hazard:after {
  content:"chemicalGas";
  @extend .hazards-main-icon;
}
.icon-flammable-hazard:after {
  content:"chemicalFlammable";
  @extend .hazards-main-icon;
}
.icon-environment-hazard:after {
  content:"chemicalEnvironmental";
  @extend .hazards-main-icon;
}
.icon-chronic-hazard:after {
  content:"chemicalChronic";
  @extend .hazards-main-icon;
}


.icon-biological-hazard:before,
.icon-radiation-hazard:before {
  content: "triangleBack";
  color: #FFDC32;
  margin-right: -1em;
}
.icon-biological-hazard,
.icon-radiation-hazard {
  color:black;
  font-size: 32px;
}
.icon-biological-hazard:after{
  content:"triangleBiological-hazard";
  color:black;
  margin-left: -1em;
}
.icon-radiation-hazard:after{
  content:"triangleRadiation";
  color:black;
  margin-left: -1em;
}
