@import
  "_variables",
  "_functions",
  "_vendor-prefixes",
  "_vendor-prefix-animation",
  "_grid",
  "_typography",
  "_commons",
  "_components",
  "_fonts",
  "_landing-pages",
  "_action-menu",
  "_overview-menu",
  "_search",
  "_field",
  "_error-warning-alert";

//**********from foundation***********
html, body { height: 100%; }

img {
  display: inline-block;
  vertical-align: middle;
  border: 0;
}

a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

button {
  overflow: visible;
}
a {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

textarea { //Remove default vertical scrollbar in IE 8/9.
  overflow: auto;
}

table { //Remove most spacing between table cells.
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th { //Remove most spacing between table cells.
  padding: 0;
}

*,
*:before,
*:after {
  @include box-sizing-mixin(border-box);
}
/*****************************************************/
.large-checkbox {
  .mat-checkbox-inner-container {
    height: 35px;
    width: 35px;
  }
  .mat-checkbox-background, .mat-checkbox-frame {
   border-radius: $border-radius-buttons;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 35px;
  }
}

.small-radio {
  .mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
    height: 15px;
    width: 15px;
  }
  .mat-radio-button .mat-radio-ripple {
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
  }
}
.removePaddingInMatMenu{
  .mat-menu-content{
    padding: 0 !important;
    min-width: 220px !important;
  }
}

/********************* animation ****************************/
.heartbeat{
  position: absolute;
  top: -20px;
  right: -13px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 4px solid $orange-color;
  @include border-radius-mixin(70px);
  @include animation-mixin(heartbeat, 1s, ease-out, none, 0s, infinite);
}
@include keyframes-mixin(heartbeat){
  0%{transform:scale(0);opacity:0}
  25%{transform:scale(0.1);opacity:.1}
  50%{transform:scale(0.5);opacity:.3}
  75%{transform:scale(0.8);opacity:.5}
  100%{transform:scale(1);opacity:0}
}
.point{
  width: 6px;
  height: 6px;
  @include border-radius-mixin(30px);
  background-color: $orange-color;
  position: absolute;
  right: -3px;
  top: -10px;
}

/*****************************************************/
div.vertical-line{
  width: 1px;
  height: 24px;
  background-color: $textColorGray;
  position: relative;
  display: inline-block;
}

.flex-grow{
  @include flex-grow(1);
}

.verticalAlign {
  @include verticalAlign(translateY(-50%));
}

.verticalAlign-content {
  @include display-inline-flex();
  @include align-flex-items(center);
  vertical-align: middle;
}

.marked {
  opacity: .50;
}
/*************job-status***************/
.job-status-message-list,
.job-status__alert-container,
.job-status {
  .finished { background: $green; }

  .active { background: $bg-blue; }

  .failed { background: $errorColor; }
}


.job-status__spinner-icon {
  font-size: 8px;
  color:$white;
  @include animation-mixin(job-status-spinner, 2s, linear, forwards, 0s, infinite);
}

.job-status__activated-spinner-icon {
  font-size: 30px;
  @include animation-mixin(job-status-spinner, 2s, linear, forwards, 0s, infinite);
}

@include keyframes-mixin(job-status-spinner){
  0% { @include transform-mixin(rotate(0deg)); }
  100%{ @include transform-mixin(rotate(360deg)); }
}

.job-status__finished-icon {
  font-size: 12px;
  height: 12px !important;
  width: 12px !important;
}

.job-status__failed-icon{
  font-size: 12px;
  height: 12px !important;
  width: 12px !important;
}
.tone-down-color { color: $textColorPlaceholderInput; }

/************* authority-ribbon *************/
.authority-ribbon-container{
  overflow: hidden;
  position: absolute;
}

.authority-ribbon{
  @include display-flex();
  @include align-flex-items(center);
  @include justify-content(center);
  position: relative;
  color: $black;
  width: 100%;
  @include border-radius-mixin($border-radius-buttons);
  span{
    line-height: $normal-line-height;
  }
  &.situla{
    background-color: $light-green;
  }
  &.ribbon-culture-hub{
    background-color: $orange-color3;
  }
}
/**********************/
.row .row {
  margin-left:0;
  margin-right:0;
}

.column, .columns {
  padding-left:0;
  padding-right: 0;
}

a {
  color: $textColor;
}

img, svg {
  max-width: 100%;
}

li {
  position: relative;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
div:not(.mat-form-field-infix) > label{
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  cursor: pointer;
}
hr {
  border: solid $borderColor;
  border-width: 1px 0 0;
  margin: $XS 0 0;
  clear: both;
}


input, textarea, select {
  @include border-radius-mixin(1px);
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.relative {
  position: relative;
}

.displayNone {
  display: none;
}

.displayInlineBlock {
  display: inline-block;
}

.lowercase {
  text-transform: lowercase;
}
.fixed-position {
  position: fixed;
  bottom: 0;
  top:0;
  left: 0;
  right: 0;
}
.full-width {
  width: 100%;
}
//*************app.component.html*************************//
.app-component {
  &__main-menu { height: $mainMenuHeight; }

  &__main-menu-container {
    position: absolute;
    left: 0;
    right: 0;
  }
  &__main-menu-content {
    background: $menu-background;
    height: $mainMenuHeight;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zIndex-mainMenu;
  }
  &__container {
    float: left;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  &__content {
    float: left;
    width: 100%
  }
}

.authentication-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  position: relative;

  &__loading-container,
  &__choose-backend {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &__loading-container { z-index: 800; }

  &__choose-backend { z-index: 801; }

  &__loading {
    @include display-flex();
    width: 100%;
    height: 100%;
    @include justify-content(center);
    @include flex-flow(column);
    @include align-flex-items(center);
    position: relative;

    i {
      font-size: 48px;
      position: absolute;
      top:100px;
      @include display-flex();
      @include justify-content(center);
      width: 100%;
    }
  }
  &__spinner-container {
    position: absolute;
    top:250px;
    @include display-flex();
    @include justify-content(center);
    @include align-flex-items(center);
    @include flex-flow(column);
    width: 100%;
  }
  &__spinner { margin: $L 0; }

  &__auth-error-container{
    position: absolute;
    width: 100%;
    left: 0;
    top: 190px;
    z-index: 2000;
    @include display-flex();
    @include align-flex-items(center);
    @include justify-content(center);

    span {
      width: 200px;
      max-width: 200px;
    }

    i {
      font-size: 48px;
      position: absolute;
      top:-80px;
      @include display-flex();
      @include justify-content(center);
      width: 100%;
    }
  }

  &__auth-error {
    color: #dd2c00;
    border: 1px solid #dd2c00;
    padding: $XS;
    border-radius: 4px;
    @include display-flex();
    @include align-flex-items(center);
    @include justify-content(center);
    background: #FCC;
    span{
      font-size: 12px;
      margin-left: $XS;
    }
  }
}

/****************body classes**********************/

//.body-image-gallery {
//  overflow-y: scroll;
//}

.body-image-annotate {
  .wrapper {
    float: left;
    height: 100%;
    width: 100%;
  }
  .topBar-fixed {
    width: 100%;
  }
  .side-menu-button {
    display: none;
  }
  .annotate-parent-container {
    @include calc-mixin(height, '100vh - 50px');
  }
}

.search-page {
  @include display-flex();
  @include flex-flow(column nowrap);
  background-color: $background;
  width: 100%;
  height: 100%;
  .app-component {
    &__container {
      @include display-flex();
      width: 100%;
      @include calc-mixin(height, '100% - #{$mainMenuHeight}');
    }
    &__content {
      position:relative;
      z-index: 1;
      section {
        height: 100%;
      }
    }
  }
}

.home {} //front page

.artifact {
  //overflow-y: scroll;
}
/*****************************/
.annotate-dashboard { //scrollbar on annotation page
  .annotation-points__bottom,
  .annotate-dashboard__column-helper-container {
    &::-webkit-scrollbar-track{
      @include box-shadow-mixin(inset 0 0 0 3px $filter-menu-scrollbar2);
      background-color: $filter-menu-scrollbar;
    }
    &::-webkit-scrollbar{
      width: 7px;
      background-color: $filter-menu-scrollbar2;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $filter-menu-scrollbar;
    }
  }
}
.annotation-dialog {
  .annotation-points__bottom,
  .annotation-dialog__dashboard {
    &::-webkit-scrollbar-track{
      background-color: $gray;
      padding-top: 75px;
      border-left: 1px solid $border-color;
    }
    &::-webkit-scrollbar{
      width: 9px;
    }
    &::-webkit-scrollbar-thumb{
      border: 2px solid $gray;
      border-radius: 4px;
      background-color:  $scrollbar-thumb-admin;
    }
  }
}
/****************************/
.selector-open { //class is added to body tag when selector is open
  overflow:hidden;
  height: initial;
}

