.search-page {
  .authority-ribbon-container{
    min-width: 50px;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    @media only screen and (max-width: $searchListThumbs) {
      bottom: -20px;
      right: 20px;
      left:auto;
    }
  }
  .authority-ribbon{
    padding-left: $XS;
    padding-right: $XS;
    font-size: 10px;
  }
}
/***************************************************************/
.search-result-views-inner-container {
  float: left;
  @include calc-mixin(height, '100% - 70px');
  width: 100%;
  &:before,
  &:after {
    content: " ";
    clear: both;
  }
}

.search-view {
  &:before, &:after {
    content: " ";
    display: table;
  }
}


/**************** search-menu, filter, sort and view buttons *****************/

.common-search-menu {
  button {
    width: 100%;
    text-align: left;
    padding-left: $M;
    height: 36px;
    line-height: 36px;
    @include display-flex();
    @include align-flex-items(center);
  }
  &__radio-text {
    margin-left: 12px;
    font-size: $normal-font-size;
    @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
      margin-left: 0;
    }
  }

  .mat-radio-button { width: 100%; }
}

/********************/

.common-operation-menu-styling {
  height: 100%;
  &__button{
    @include display-flex();
    @include align-flex-items(center);
    vertical-align: middle;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-size: $normal-font-size;
    padding-right: $S;
    .mat-icon {
      @include flex-grow(1);
      color: $textColorPlaceholderInput;
      text-align: right;
    }

    &[disabled] {
      color:$textColorPlaceholderInput;
    }
    @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
      padding: 34px $L 0 $L;
      @include justify-content(center);
      @include flex-flow(row wrap);
      .mat-icon {
        width: 100%;
        text-align: center;
      }
    }
    @media only screen and (max-width: $searchTopMenuButtonsMobile) {
      padding: 34px $XS 0 $XS;
    }
  }

  &__text1{
    display: inline;
    float: left;
  }

  &__text2{ display: none; }

  @media screen and (max-width: $searchTopMenuSmallScreen) {
    &__text1{ display: none; }
    &__text2{
      display: inline;
      float: left;
    }
  }

  &__button-text { margin-right: $XS; }
  &__sub-operation-button {
    width: 100%;
    text-align: left !important;
    padding-left: 34px !important;
    font-size: $normal-font-size !important;
    i {
      margin-right: $S;
      font-size: 16px;
      line-height: 20px;
    }
    .mat-icon {
      margin-right: 6px;
      font-size: 20px;
      height: 20px;
      width: 20px;
    }
    ::ng-deep .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &.active {
    .common-operation-menu-styling__button-text,
    .common-operation-menu-styling__button-count,
    .common-operation-menu-styling__button .mat-icon{
      color: $white !important;
    }
  }
}
.common-search-menu-backdrop {
  z-index: 501 !important;
}

/***********************/

.search-top-menu-buttons {
  display: inline-block;
  height: $searchPage-actionMenu;
  border: 1px solid $stroked-button-border-color;
  width: 206px;
  float: left;
  cursor: pointer;
  font-size: $normal-font-size;

  @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
    width: auto;
    @include display-flex();
    @include justify-content(center);
    vertical-align: middle;
    @include flex-flow(column nowrap);
    margin-left: $XS;
    height: $searchPage-actionMenu-SmallScreen;
    min-width: 120px;
  }
  @media only screen and (max-width: $searchTopMenuButtonsCollapseSmall) {
    min-width: initial;
  }
}
/***********************************************************************/
.list-item-icon {
  font-size: $normal-icon-font-size;
  margin-right: $S;
  float: left;
  line-height: 1;
  width: 16px;
  height: 16px;
  color: $textColor;
}
.searchIcon{
  color: $textColor;
  margin-right: $XS;
}

.searchResultPager-container {
  position: relative;
  width: 100%;
  float: left;
}
/************search-result-pager-list, list-pager**************************/
.search-result-pager-content-list {
  margin-top: $M;
  .page-item {
    display: inline-block;
    margin-left: $S;
  }
  .current-page{
    @include border-radius-mixin($border-radius-buttons);
    color:$white;
    padding-left: $XS;
    padding-right: $XS;
    text-align: center;
    display: inline-block;
    background-color: $image-background;
  }
  .page-item-first-page{
    margin-left: $XS;
    margin-right: -$XS;
  }
  .page-item-last-page{
    margin-left: $XS;
    .last-page-ellipsis{
      margin-right: $XS;
    }
  }
  .disabled {
    @include box-shadow-mixin(none);
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
    filter: alpha(opacity=65);
  }
}
/***************************************/

.checkbox-underlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zIndex-thumbnail-checkbox;
  line-height: $normal-line-height;
  background: rgba(51, 51, 51, 0.1);
  padding: $S;
  @include display-flex();
  @include align-flex-items(center);
  @include justify-content(center);
  .mat-checkbox{
    @include display-inline-flex();
  }
}
/************************************/
.scroll-viewport-height {
  &:not(.folder) { @include calc-mixin(height, '100vh - 214px'); }

  &.folder:not(.open) { @include calc-mixin(height, '100vh - 336px'); }

  &.folder.open { @include calc-mixin(height, '100vh - 432px'); }

  @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
    &:not(.folder) { @include calc-mixin(height, '100vh - 238px'); }

    &.folder:not(.open) { @include calc-mixin(height, '100vh - 360px'); }

    &.folder.open { @include calc-mixin(height, '100vh - 456px'); }
  }
  @media screen and (max-width: $breakPoint-small) {
    &:not(.folder) { @include calc-mixin(height, '100vh - 288px'); }

    &.folder:not(.open) { @include calc-mixin(height, '100vh - 410px'); }

    &.folder.open { @include calc-mixin(height, '100vh - 506px'); }
  }
}
/****************************************/
.search-result-view-thumbnail {
  &__headline{
    a {
      @extend .text-link;
      @include ellipsis-multiple-lines-2-mixin(3);
      max-height: 50px; // 3 lines
    }
    span {
      line-height: $normal-line-height;
      word-wrap: break-word;
    }
    .searchIcon {
      font-size: $normal-icon-font-size;
      float: left;
    }
  }
  &_headline-url {
    span {
      color: $textColor;
      font-size: $normal-font-size;
    }
  }
  .search-tile-overview-fields__value{
    .object-link {
      wbr {
        display: none;
      }
    }
    .overview-field-value__notLink-text {
      wbr {
        display: none;
      }
    }
  }
}
/*********************list-thumbnails commons***************************/
.list-thumbnail {
  margin-left: 0;
  &__item {
    line-height: $normal-line-height;
    margin-bottom: $S;
    background-color: $white;
    text-align: left;

    @media only screen and (max-width: $searchListThumbs) {
      padding-left: 0;
    }
  }
  &__frame-and-shadow{
    .list-thumbnail__item {
      border: 1px solid $thumbnail-border;
      @include box-shadow-mixin(0px 2px 5px -1px $thumbnail-border);
    }
  }

  &__row {
    @include display-flex();
    @include flex-flow(row nowrap);
    width: 100% !important; //overruled by .row
    //height: 1px; // hack for ff & IE

    @media only screen and (max-width: $searchListThumbs) {
      @include flex-flow(column);
    }
  }
  &__first-container {
    position: relative;
    background-color: $gray2;
    @include display-flex();
    @include align-flex-items(center);
    @include justify-content(center);
    height: 100%;

    &.folder {
      background-color: $white;
      border-right: 1px solid $gray2;
    }

    @media only screen and (max-width: $searchListThumbs) {
      margin-right: 0;
    }
  }

  &__second-container {
    text-align: left;
    padding: $S $S $S $L;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    @include display-flex();
    @include flex-flow(column);

    @media only screen and (max-width: $searchListThumbs) {
      padding: $M $L;
      width: 100% !important;
      &.no-image {
        margin-top: $L;
        padding-top: $L;
      }
    }

    .field {
      margin-bottom: 0;
      .field-content {
        .multi-value-with-showMore {
          .noShowMore {
            float: left;
            margin-right: $XS;
            max-width: 90%;
            height: 16px;
            @include ellipsis-mixin();
          }
        }
      }
    }
  }
  &__object-overview {
    float: left;
    width: 100%;
  }
}
/****************** common in list-thumbnail, folder, selector, source array and content-list************************/
.list-overview-fields {
  &__headline {
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      float: left;
      width: 100%;
      line-height: $normal-line-height;
    }
  }
  &__headline-text {
    font-size: $normal-font-size;
    float: left;
    @include calc-mixin(width, '100% - 26px');
    line-height: $normal-line-height;
    @include  ellipsis-multiple-lines-2-mixin(2);
  }
  &__content {
    @include display-flex();
    @include calc-mixin(padding-left, '#{$listIconSize} + #{$S}');
    padding-top: $XS;
    width: 100% !important;

    @media only screen and (max-width: 600px){
      padding-left: 0;
    }
  }
  &__field {
    margin-bottom: 0;
    height: 100%;
    float: left;
    width: 100%;
    position: relative;
    display: flex;
  }
  &__field-title {
    //display: table-cell;
    white-space: nowrap;
    //vertical-align: top;
    line-height: $normal-line-height;
    span{
      color: $textColorPassive;
      font-size: $search-result-font-size;
      padding-right: $S;
      display: inline-block;
    }
  }
  &__field-content {
    //display: table-cell;
    //float: none;
    width: 100%;
    //clear: both;
    //vertical-align: top;
    @include user-select-text();
    overflow: hidden;
  }
}
/***************overview fields in list-thumbnails and folder***********************/
.search-result-overview-fields {
  &__headline-text {
    @media only screen and (max-width: 600px){
      @include ellipsis-multiple-lines-2-mixin(1);
    }
  }
}
/***************overview fields in content-list, selector and source array ***********************/
.content-selector-source {
  &__content {
    @include flex-flow(row nowrap);

    &.selector {
      width: 100%;
      padding-left: 0;
      padding-right: $S;
    }
  }
}
/************************ procedure-content-list *****************************************/
.procedure-content-list {
  width: 100%;
  height: 100%;
  float: left;
  &__title {
    width: 100%;
    h3 {
      float: left;
      margin-right: $largePadding;
    }
  }
  .add-button .mat-icon{ margin-right: $XS;}

  &__toggle-button {
    width: 60px;
    height: 100%;
    @include display-flex();
    @include justify-content(center);
    @include align-flex-items(center);
    font-size: 16px;
    margin-left: -$L;
  }
  &__content-list{
    width: 100%;
  }
  &.smallScreen {
    .procedure-content-list__title{
      @include display-flex();
      @include align-flex-items(center);
      height: 80px;
      h3 {
        overflow: hidden;
        margin-right: $S;
        @include calc-mixin(max-width, '100% - 80px');
        width: auto;
        flex: 1 auto;
      }
    }
  }
}
/******************material-design-table*******************************/
.md-table {
  &__name {
    float: left;
    @include calc-mixin(width, '100% - 33px');
  }

  &__checkbox {
    .mat-checkbox{
      @include display-flex();
    }
  }
  table {
    text-align: left;
    width: 100%;
    vertical-align: top;
    white-space: nowrap;
    th {
      font-weight: 300;
      white-space: nowrap;
      border-right: 1px solid $white;

      &:last-of-type {
        border-right: 0;
      }

      .sortDesc, .sortAsc {
        display: none;
        margin-left: $S;
        position: absolute;
        line-height: $normal-line-height;
        font-size: 12px;
      }
    }
    .sorting-enabled {
      cursor: pointer;
      &:not(.active) {
        &:active, &:hover, &:focus{
          background-color: $gray3;
          .sortAsc{
            display: inline-block;
            color: $textColorPlaceholderInput;
          }
        }
      }
      &.active{
        .decreasing{
          display: inline-block;
        }
        .increasing{
          display: inline-block;
        }
        .sortDesc, .sortAsc {
          color: $textColorPassive;
        }
      }
    }
    tr {
      border-bottom: 1px solid $borderColor;
    }
    td {
      border:none;
    }
  }

  .mat-header-row{
    height: auto !important;
  }
  .mat-header-cell, .mat-cell{
    float: none !important;
    text-align: left;
    padding: $XS $S !important;
    font-size: $normal-font-size;
    line-height: $normal-line-height;
    color: $textColor;
  }
  .locked-column {
    border-right: 1px solid $borderColor;
    @include box-shadow-mixin(2px 2px 4px -1px rgba(0,0,0,0.21));
  }
  .cdk-column-select, .cdk-column-marked, .cdk-column-valid {
    width: 45px;
    text-align: center;
  }
  .action-icon-in-table {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}
/************** information-tabs table ******************/
.information-tabs-table {
  th {
    font-weight: 300;
    white-space: nowrap;
    border-right: 1px solid $borderColor-top;
    border-bottom-color: $borderColor-top !important;

    &:last-of-type {
      border-right: 0;
    }
  }
  tr {
    border-bottom: 1px solid $borderColor-top;
  }
  td {
    border:none;
    border-right: 1px solid $borderColor-top;
    &:last-of-type {
      border-right: 0;
    }
  }
  .mat-header-row{
    height: 48px;
    @include box-shadow-mixin(0 4px 6px 0 rgba(77,90,94,0.15));
  }

  .mat-header-cell, .mat-cell{
    float: none !important;
    text-align: left;
    padding: $XS $S !important;
    font-size: $normal-font-size;
    line-height: $normal-line-height;
    color: $textColor;
  }
}


