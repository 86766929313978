/* You can add global styles to this file, and also import other style files */
//// Load material-icons
@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

/**************custom color themes explanation*************************/
//Primary - Main colors most widely used across all screens and components.
//Accent - Also known as the secondary color. Used for floating action buttons and interactive elements.
//Warn - Colors to convey error state.
//Foreground - Used for text and icons.
//Background - Colors used for element backgrounds.

// mat.define-palette(palette, default, lighter, darker)
/************************************************************/

$primary: mat.define-palette(mat.$indigo-palette, 900);
$accent: mat.define-palette(mat.$light-blue-palette, A400, 50);
$warn: mat.define-palette(mat.$deep-orange-palette, A700);
$theme: mat.define-light-theme($primary, $accent, $warn);


@include mat.all-legacy-component-themes($theme);

@mixin set-active($color-theme, $color) {
  background: mat.get-color-from-palette($color-theme, $color);
  border-color: mat.get-color-from-palette($color-theme, $color);
  color: mat.get-color-from-palette(mat.$grey-palette, A100);
}

.mat-flat-button.mat-button-base[aria-expanded="true"],
.mat-flat-button.mat-button-base.active,
.mat-stroked-button.mat-button-base[aria-expanded="true"],
.mat-stroked-button.mat-button-base.active {
  @include set-active(mat.$gray-palette, 400);
}

.mat-flat-button.mat-primary[aria-expanded="true"],
.mat-flat-button.mat-primary.active,
.mat-stroked-button.mat-primary[aria-expanded="true"],
.mat-stroked-button.mat-primary.active {
  @include set-active(mat.$indigo-palette, 500);
}

.mat-flat-button.mat-accent[aria-expanded="true"],
.mat-flat-button.mat-accent.active,
.mat-stroked-button.mat-accent[aria-expanded="true"],
.mat-stroked-button.mat-accent.active {
  @include set-active(mat.$light-blue-palette, A700);
}
.mat-flat-button.mat-warn[aria-expanded="true"],
.mat-flat-button.mat-warn.active,
.mat-stroked-button.mat-warn[aria-expanded="true"],
.mat-stroked-button.mat-warn.active {
  @include set-active(mat.$deep-orange-palette, 400);
}

.mat-flat-button.mat-accent {
  color: mat.get-color-from-palette(mat.$grey-palette, A100);
}

.mat-flat-button.mat-accent:not([disabled]) {
  &:active, &:hover, &:focus {
    @include set-active(mat.$light-blue-palette, A700);
  }
}

.mat-button.mat-button-base[aria-expanded="true"],
.mat-button.mat-button-base.active {
  color: mat.get-color-from-palette($accent, default) !important;
  .mat-icon, span {
    color: mat.get-color-from-palette($accent, default) !important;
  }
}

.white-bg-in-checkbox {
  .mat-checkbox-frame {
    background-color: mat.get-color-from-palette(mat.$grey-palette, A100);
  }
}
.white-bg-in-radio-button {
  .mat-radio-outer-circle {
    background-color: mat.get-color-from-palette(mat.$grey-palette, A100);
  }
}


.mat-header-row {
  background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
  th {
    &.sorting-enabled {
      &:not(.active) {
        &:active, &:hover, &:focus{
          background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 100);
        }
      }
      &.active {
        background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 100);
      }
    }
  }
}
.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled){
  background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
}
.predicate-group-color{
  background: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
}

button.mat-menu-item{
  &.active, &:hover:not([disabled]) {
    background: mat.get-color-from-palette($accent, lighter);
  }
}

.mat-expansion-panel.mat-expanded:not(.no-active-blue-color),
.mat-expansion-panel:not(.mat-expanded):not(.no-active-blue-color) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
  background: mat.get-color-from-palette($accent, lighter);
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.faq-page,
.admin {
  background: mat.get-color-from-palette(mat.$grey-palette, A100);
}
/**********************colors*************************************/
.error-warn-bg-color {
  background-color:  mat.get-color-from-palette(mat.$deep-orange-palette, 100);
}
.error-warn-border-color {
  border-color: mat.get-color-from-palette(mat.$deep-orange-palette, A700);
}
.error-warn-color {
  color: mat.get-color-from-palette(mat.$deep-orange-palette, A700);
}

.normal-bg-color {
  background: mat.get-color-from-palette(mat.$grey-palette, 100);
}

.bg-blue {
  background-color: mat.get-color-from-palette(mat.$light-blue-palette, A400) !important;
}
.bg-blue-hover {
  &:active, &:hover, &:focus {
    background-color: mat.get-color-from-palette(mat.$light-blue-palette, A400);
  }
}
.bg-white {
  background-color: mat.get-color-from-palette(mat.$grey-palette, A100);
}
.light-gray-bg-color {
  background: mat.get-color-from-palette(mat.$grey-palette, 50);
}

.border-blue {
  border-color: mat.get-color-from-palette(mat.$light-blue-palette, A400) !important;
}

.text-blue {
  color: mat.get-color-from-palette(mat.$light-blue-palette, A400);
}
.text-blue-hover:not([disabled]) {
  &:active, &:hover, &:focus {
    color: mat.get-color-from-palette(mat.$light-blue-palette, A400) !important;
  }
}
.color-white {
  color: mat.get-color-from-palette(mat.$grey-palette, A100);
}


.blue-hover-icon {
  &:active, &:hover, &:focus {
    i, .mat-icon {
      color: mat.get-color-from-palette(mat.$light-blue-palette, A400) !important;
    }
  }
}
.light-bg-blue {
  background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
}

/********************************************************/
.edit-field-selected-options {
  color: mat.get-color-from-palette(mat.$indigo-palette, 900);
  &:hover, &:active, &:focus {
    .button-overlay {
      opacity: .04;
      background-color: mat.get-color-from-palette(mat.$indigo-palette, 900);
    }
  }
}
.media-carousel__size-buttons {
  color: mat.get-color-from-palette(mat.$indigo-palette, 900);
  &.active {
    background-color: mat.get-color-from-palette(mat.$indigo-palette, 900) !important;
  }
}

.change-image-order__drop-down-item {
  &:hover, &:active, &:focus, &.active {
    background: mat.get-color-from-palette($accent, lighter);
  }
}

.edit-field-select-hierarchy-node {
  &:hover, &:active, &:focus {
    .selectedChild-button {
      background-color: mat.get-color-from-palette($accent, lighter);
    }
  }
}

.edit-field-select-hierarchy-node .selectedRow,
.node-level-1 .selectedNodeRow,
.node-level-child .selectedChildNodeRow,
.field-select-options__option.selectedRow,
.field-select-options__option.selectedOption{
  background-color: mat.get-color-from-palette($accent, lighter);
}

.edit-field-select-hierarchy-node__node,
.field-select-options__option {
  &:hover, &:active, &:focus {
    background-color: mat.get-color-from-palette($accent, lighter);
  }
}
.object-menus-small__dropdown {
  li {
    &:hover, &:active, &:focus {
      .obj-menus-commons-menuGroup-btn {
        background-color: mat.get-color-from-palette($accent, lighter);
      }
    }
  }
  .activeMenuWithSub{
    li{
      &:hover, &:active, &:focus {
        background-color: mat.get-color-from-palette($accent, lighter);
      }
    }
  }
}

.filter-checkbox {
  .mat-checkbox-frame {
    border-color: #999;
  }
  .mat-checkbox {
    display: block;
    &:active, &:hover, &:focus {
      font-family: 'Roboto-bold', sans-serif !important;
    }
  }
  .mat-checkbox-layout,
  .mat-checkbox-label {
    width: 100%;
  }
  .mat-checkbox-label {
    overflow: hidden;
  }
}
.gray-radio-button {
  .mat-radio-outer-circle{
    border-color: #999;
  }
  &.mat-radio-disabled{
      .mat-radio-outer-circle {
        background-color: #999;
        border-color: #999;
      }
  }
}
/****************************************/
.view-bg-color-75 {
  background: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
}
.view-hover-color {
  &:active, &:hover, &:focus {
    background: mat.get-color-from-palette(mat.$blue-grey-palette, 100);
  }
}
.view-bg-color-200 {
  background: mat.get-color-from-palette(mat.$blue-grey-palette, 200);
}
/*******************************************************************/
.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    background-color: white;
    border-radius: 3px !important;
  }
  .mat-form-field-outline-start {
    border-radius: 3px 0 0 3px !important;
  }
  .mat-form-field-outline-end {
    border-radius: 0 3px 3px 0 !important;
  }
}
/*******************************************************************/

.small-mat-form-outline {
  padding-right: 10px;
  .mat-form-field-flex > .mat-form-field-infix {
    padding-top: 0.2em !important;
    padding-bottom: 0.67em !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.2em;
  }
  .mat-form-field-label-wrapper {
    top: -1.6em;
    padding-top: 1.6em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    top:0;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.2em) scale(.75);
    width: 133.33333%;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0;
  }

  .mat-form-field-appearance-outline ::ng-deep .mat-form-field-outline-thick {
    height: 37px;
  }
}

.clear-icon,
.search-icon {
  display: inline-block !important;
}

.clear-icon {
  font-size: 12px !important;
  margin-right: 0 !important;
  color: #999;
}

.media-list__files.active {
  .media-list__files-img-container,
  .media-list__files-content {
    border-color: mat.get-color-from-palette(mat.$light-blue-palette, A400, 0.5) !important;
  }
}
/*************************dialog***************************************/
$dialog-primary: mat.define-palette(mat.$brown-palette, 700, 50);
$dialog-accent: mat.define-palette(mat.$orange-palette, 800);
$dialog-theme: mat.define-light-theme($dialog-primary, $dialog-accent, $warn);

.dialog {
  @include mat.all-legacy-component-themes($dialog-theme);
  background: #fffaf6;
  border-color: mat.get-color-from-palette($dialog-primary, lighter);

  .mat-flat-button.mat-primary[aria-expanded="true"],
  .mat-flat-button.mat-primary.active,
  .mat-stroked-button.mat-primary[aria-expanded="true"],
  .mat-stroked-button.mat-primary.active {
    @include set-active(mat.$brown-palette, 400);
  }

  .mat-flat-button.mat-accent[aria-expanded="true"],
  .mat-flat-button.mat-accent.active,
  .mat-stroked-button.mat-accent[aria-expanded="true"],
  .mat-stroked-button.mat-accent.active {
    @include set-active(mat.$deep-orange-palette, 800);
  }
  .mat-primary.active {
    background: mat.get-color-from-palette($dialog-primary, lighter);
  }
  .mat-flat-button.mat-accent:not([disabled]) {
    @include set-active(mat.$orange-palette, 800);
    &:active, &:hover, &:focus {
      .mat-button-focus-overlay {
        opacity: 0.04;
      }
    }
  }
  .mat-stroked-button {
    font-size: 13px;
    line-height: 26px;
    border-color: mat.get-color-from-palette($dialog-primary, lighter);
  }
  .mat-stroked-button.mat-primary[aria-expanded="true"] {
    color: mat.get-color-from-palette(mat.$grey-palette, A100);
  }

  .dialog-front-color {
    background: mat.get-color-from-palette($dialog-primary, lighter);
  }
  .dialog-accent-color {
    color: mat.get-color-from-palette($dialog-accent, default);
  }
  .dialog-primary-color {
    color: mat.get-color-from-palette($dialog-primary, default);
  }
  .dialog-accent-bg-color {
    background: mat.get-color-from-palette($dialog-accent, default);
  }
  .dialog-border-color {
    border-color: mat.get-color-from-palette($dialog-primary, lighter) !important;
  }
  .edit-field-selected-options {
    color: mat.get-color-from-palette($dialog-primary, default);
    &:hover, &:active, &:focus {
      .button-overlay {
        opacity: .04;
        background-color: mat.get-color-from-palette($dialog-primary, default);
      }
    }
  }
  .operation-dialog-step-indicator-bg {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 50);
    border: 1px solid mat.get-color-from-palette(mat.$brown-palette, 700)
  }
  .operation-dialog-step-indicator-bg-active {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 700);
  }
  .edit-field-inline-array-item__top_bar {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 50) !important;
    &:hover, &:focus, &:active, &.active {
      background-color: mat.get-color-from-palette(mat.$brown-palette, 100) !important;
    }
  }
  .edit-field-inline-array-item__content {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 50) !important;
  }

  .media-list__files-img-container,
  .media-list__files-content,
  .media-list__files-img-container__annotate-button{
    background-color: mat.get-color-from-palette(mat.$brown-palette, 100, 0.2) !important;
    border-color: mat.get-color-from-palette(mat.$brown-palette, 100) !important;
  }

  .media-list__files.active {
    .media-list__files-img-container,
    .media-list__files-content {
      background-color: mat.get-color-from-palette(mat.$brown-palette, 100, 0.5) !important;
      color: #333 !important;
      border-color: mat.get-color-from-palette($dialog-accent, default, 0.5) !important;
    }
    .media-list__files-img-container__annotate-button{
      background-color: mat.get-color-from-palette(mat.$brown-palette, 100, 0.5) !important;
      color: #333 !important;
    }
  }
  .media-list__select-button.mat-expansion-panel.mat-expanded{
    background: mat.get-color-from-palette(mat.$brown-palette, 100) !important;
    border-color: mat.get-color-from-palette(mat.$brown-palette, 700) !important;
  }

  .cdk-drag-dragging, .cdk-drag-preview {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 50) !important;
  }

  .mat-header-row {
    background-color: mat.get-color-from-palette(mat.$brown-palette, 50);
    th {
      &.sorting-enabled {
        &:not(.active) {
          &:active, &:hover, &:focus{
            background-color: mat.get-color-from-palette(mat.$brown-palette, 100);
          }
        }
        &.active {
          background-color: mat.get-color-from-palette(mat.$brown-palette, 100);
        }
      }
    }
  }

  .annotation-dashboard-buttons__cancel {
    color: mat.get-color-from-palette($dialog-accent, default) !important;
  }
  .mat-header-cell {
    border-bottom-color: #E6E6E6;
    color:initial;
  }
}
/***************************************/
.admin-settings-setting, .admin-settings-faq {
  .mat-card-header {
    .mat-card-header-text {
      flex-grow: 1;
      margin:0;
    }
  }
  .mat-card-header .mat-card-title {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .mat-form-field-underline{
    background-color: #cfd8dc;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: #E8EAED;
  }
}
/************************************/
.mat-menu-panel.leave-focus-menu {
  background-color: #576062; //$focus-menu-button;
  border: 1px solid  #28383e; //$focus-menu-button-border;
  border-radius:0;
  min-height: auto;
  button {
    color:white;
    font-size: 12px;
    width: 100%;
  }
}
.mat-menu-panel.common-operation-menu-styling__menu {
  width: 400px;
  max-width: initial;
  margin-left: -36px;
  @media only screen and (max-width: 850px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  .mat-expansion-panel-header { font-size: 13px; }
  .mat-expansion-panel-body { padding: 0 0 16px !important; }
  .mat-list-base{ padding-top:0; }
}

.mat-list.common-search-menu {
  padding-top:0;
  min-width: 204px;
}
.mat-list.overview-show-more-menu {
  padding-top:0;
  .mat-list-item{
    height: 36px;
    line-height: 36px;
    font-size: 13px;
  }
}
.mat-menu-panel.overview-show-more-menu-panel {
  min-height: auto;
}
/*************************************/
.search-input-menu-drop-down{
  .mat-menu-item{
    font-size: 13px;
    .mat-icon {
      margin-right: 5px;
    }
  }
  .mat-menu-content {
    min-width: 240px;
  }
  &__icon {
    &.mat-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 16px;
    }
  }

}

.search-input-menu-trigger {
  .mat-menu-trigger {
    &.active,
    &.isButton,
    &:hover {
      background: mat.get-color-from-palette($accent, default);
      .vertical-line{
        height: 100% !important;
        background-color: mat.get-color-from-palette($accent, default) !important;
      }
      span, i {
        color: mat.get-color-from-palette(mat.$grey-palette, A100);
      }
    }

    &.notButton{
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

// The d-block class must be defined here else bootstrap modals are invisible when opened
.d-block {
  display: block !important;
}

/****Dragula: drag and drop***/

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/**
 * Styles applied to the element being dragged when building
 * and advanced-search-query.
 * This element is applied directly to <body> and wil thus be outside the
 * CSS-scope of the advanced-search-component.
 * TODO: Move to external file and import (?)
 */
.advanced-search-drag-preview {

  &.cdk-drag-preview {
    min-width: 200px;
    height: 50px;
    border: 1px dashed #CCC;
    border-radius: 5px;
    color: #000;
    padding: 5px 10px;
    background-color: #FEFEFE;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

}


.search-page .user-profile {
  top: 0 !important;
}

// Fixes padding issue on remove button of mat-chip
mat-chip > mat-icon {
  padding-right: 30px !important;
}
