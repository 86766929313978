
body {
  font-family: 'Roboto', sans-serif;
  color: $textColor;
  line-height: $normal-line-height;
  font-size: rem-calc($normal-font-size-rem);
  margin:0;
  @media screen and (max-width: $breakPoint-medium-up) {
    font-size: rem-calc($smallScreenFontRemCalc);
  }
}
label, textarea, input[type="text"] {
  font-size: rem-calc($normal-font-size-rem);
  @media screen and (max-width: $breakPoint-medium-up) {
    font-size: rem-calc($smallScreenFontRemCalc);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  color:$textColor;
  margin-top: .2rem;
  margin-bottom: .4rem;
  line-height: 1.4;
}

h1, .h1 {
  font-size: rem-calc(32);
}

h2, .h2 {
  font-size: rem-calc(16);
}

h3, .h3 {
  font-size: rem-calc(22);

}

h4, .h4 {
  font-size: rem-calc($normal-font-size-rem);
  @media screen and (max-width: $breakPoint-medium-up) {
    font-size: rem-calc($smallScreenFontRemCalc);
    line-height: $normal-line-height;
  }
}
h5, .h5{
  font-size: rem-calc(18);
  line-height: 24px;
}

ul, ol, dl {
  font-size: rem-calc($normal-font-size-rem);
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: $breakPoint-medium-up) {
    font-size: rem-calc($smallScreenFontRemCalc);
  }
}

//h1.light, h2.light, h3.light, h4.light, h5.light, h6.light {
//  font-family: 'Roboto-regular', sans-serif;
//}
//
//h1.medium, h2.medium, h3.medium, h4.medium, h5.medium, h6.medium {
//  font-family: 'Roboto-regular', sans-serif;
//}
//
//h1.bold, h2.bold, h3.bold, h4.bold, h5.bold, h6.bold{
//  font-family: 'Roboto-bold', sans-serif;
//  font-weight: bold;
//}

.light {
  font-family: 'Roboto', sans-serif !important;
}

.medium {
  font-family: 'Roboto', sans-serif !important;
}

.bold {
  font-family: 'Roboto-bold', sans-serif !important;
  //font-family: 'Roboto-regular', sans-serif !important;
  //font-weight: 900;
}
.uppercase {
  text-transform: uppercase;
}
strong{
  font-family: 'Roboto-bold', sans-serif;
  font-weight: normal;
}
.span{
  font-size: rem-calc($normal-font-size-rem);
  font-family: 'Roboto', sans-serif !important;
}

.text-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.headlines.h1 {
  @extend h1;
}
.headlines.h2 {
  @extend h2;
}
.headlines.h3 {
  @extend h3;
}
.headlines.h4 {
  @extend h4;
}
.headlines.h5 {
  @extend h5;
}
.headlines.h6 {
  @extend h6;
}

h1,h2,h3,h4, h5, h6, span, a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

textarea {
  min-height: 50px;
}
/*basic styling on all inputs elements */
input[type="text"]:not(.mat-input-element),
input[type="password"]:not(.mat-input-element),
input[type="date"]:not(.mat-input-element),
input[type="datetime"]:not(.mat-input-element),
input[type="datetime-local"]:not(.mat-input-element),
input[type="month"]:not(.mat-input-element),
input[type="week"]:not(.mat-input-element),
input[type="email"]:not(.mat-input-element),
input[type="number"]:not(.mat-input-element),
input[type="search"]:not(.mat-input-element),
input[type="tel"]:not(.mat-input-element),
input[type="time"]:not(.mat-input-element),
input[type="url"]:not(.mat-input-element),
textarea,
select {
  @include box-sizing-mixin(border-box);
  @include box-shadow-mixin(inset 0 1px 2px rgba(0,0,0,.1));
  @include appearance-mixin(none);
  background-color: $white;
  font-family: inherit;
  color: $textColor;
  border: 1px solid $gray3;
  display: block;
  margin:0;
  padding: $S 25px $S $M;
  height: $normal-input-height;
  width: 100%;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  resize: vertical;
  @include placeholderColor($textColorPlaceholderInput);

  &:focus {
    box-shadow: 0 0 5px $image-background;
    background-color: $input-focus-bg-color;
    border-color: $image-background;
    outline: none;
  }
}
